import "./footer.scss"

import FBLogo from "../../images/Icon awesome-facebook-f.svg"
import React from "react"
import SocialButton from "../../components/SocialButton/SocialButton"
import TwitterLogo from "../../images/Icon awesome-twitter.svg"
import UWLogo from "../../images/uw_logo_white.svg"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo__wrap">
        <img className="footer-logo__image" src={UWLogo} />
      </div>
      <div>
        <p>© 2021 Untold Worlds - All Rights Reserved.</p>
      </div>
      <div>
        <h4>Follow us at</h4>
      </div>
      <div className="footer__socials">
        <SocialButton
          to={"https://www.facebook.com/Untold-Worlds-107754454891872"}
        >
          <img src={FBLogo} style={{ height: "20px" }} />
        </SocialButton>
        <SocialButton to={"https://twitter.com/untoldworldsapp"}>
          <img src={TwitterLogo} style={{ height: "20px" }} />
        </SocialButton>
      </div>
    </footer>
  )
}

export default Footer
