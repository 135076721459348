import { Col, Row } from "../Layout/Layout"

import React from "react"
import anvil from "../../images/anvil@2x.png"
import peaks from "../../images/peaks@2x.png"
import productImage from "../../images/example-full.jpg"
import world from "../../images/world@2x.png"

const ProductIntro = () => {
  return (
    <section className="section">
      <Row classNames="row--align-items-center">
        <Col span={4} md={12}>
          <h2 className="primary-gradient">
            Build your next campaign setting in minutes
          </h2>
          <p>
            Creating a world for your tabletop campaign can be a daunting and
            time consuming task. With Untold Worlds you can generate a hex map
            for your setting in seconds, and then spend as much time as you like
            customising it to suit your style and game system.
          </p>
          <p>
            It isn't just a map editor though. Untold World also provides wiki
            tools, templates and rollable tables to help you fill a world with
            adventure.
          </p>
        </Col>
        <Col span={8} md={12}>
          <img
            src={productImage}
            style={{ width: "100%", height: "auto" }}
            className="shadow"
            alt='A regional hexmap in a temperate climate zone'
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "4em", textAlign: "center" }}>
        <ProductFeature img={world} alt='Planet icon'>
          <h4>Generate maps in seconds</h4>
          <p>
            Looking to create a small, jungle island for a short adventure or a
            sprawling world for your next campaign? The generation tools are
            quick, easy to use, and can create maps of varying sizes.{" "}
          </p>
        </ProductFeature>
        <ProductFeature img={peaks} alt='Mountains icon'>
          <h4>Customise to your taste</h4>
          <p>
            Mountain range feeling a little less epic than hoped? Found the
            perfect spot for a sprawling swamp? use the mapping tools to adjust
            the terrain and mould the world to your vision.
          </p>
        </ProductFeature>
        <ProductFeature img={anvil} alt='Anvil icon'>
          <h4>Bring your world to life</h4>
          <p>
            Fill your world with towering castles, ancient elven ruins or long
            abandoned starships. Create and discover templates and random tables
            to help you kick start your adventures.
          </p>
        </ProductFeature>
      </Row>
    </section>
  )
}

export default ProductIntro

const ProductFeature = props => {
  return (
    <Col span={4} md={4}>
      <div className="product-feature__img-wrap">
        <img src={props.img} style={{ margin: "auto", width: "100%" }} alt={props.alt}/>
      </div>
      <div className="product-feature__content-wrap">{props.children}</div>
    </Col>
  )
}
