import { Col, Row } from "../Layout/Layout.tsx"

import React from "react"
import Step1Mp4 from "../../video/step1--mp4.mp4"
import Step1WebM from "../../video/step1.webm"
import Step2Mp4 from "../../video/step2--mp4.mp4"
import Step2WebM from "../../video/step2.webm"
import Step3Mp4 from "../../video/step3--mp4.mp4"
import Step3WebM from "../../video/step3.webm"
import Step4Mp4 from "../../video/step4--mp4.mp4"
import Step4WebM from "../../video/step4.webm"

const HowItWorks = () => {
  return (
    <section id="howTo" className={"section section--alternate-bg"}>
      <div style={{ textAlign: "center" }}>
        <h2 className="primary-gradient"> Generate your campaign's map </h2>
        <div className="section__copy-centered">
          <p>
            Getting started is as simple as picking your map's size, rolling a
            world seed and tweaking a few simple settings to your taste.
          </p>
        </div>
      </div>
      <div className="feature">
        <Row classNames="row--align-items-center row--justify-content-center">
          <Col span={4} md={6}>
            <h3>Raise the land</h3>
            <p>
              Within seconds a new hex map will be ready.Raise and lower the
              land as you see fit. Once you are ready it's time to cook up your
              climate.
            </p>
          </Col>
          <Col span={6} md={6}>
            <video
              autoPlay={true}
              playsInline={true}
              loop={true}
              muted={true}
              width={"100%"}
              style={{ maxWidth: "100%" }}
            >
              <source src={Step1WebM} type="video/webm" />
              <source src={Step1Mp4} type="video/mp4" />
            </video>
          </Col>
        </Row>
      </div>
      <div className="feature">
        <Row classNames="row--reverse row--align-items-center row--justify-content-center">
          <Col span={6} md={6}>
            <video
              autoPlay={true}
              playsInline={true}
              loop={true}
              muted={true}
              width={"100%"}
              style={{ maxWidth: "100%" }}
            >
              <source src={Step2WebM} type="video/webm" />
              <source src={Step2Mp4} type="video/mp4" />
            </video>
          </Col>
          <Col span={4} md={6}>
            <h3>Adjust the climate</h3>
            <p>
              Tweak the temperature, rainfall, wind and various other climate
              factors to set the climate of your map. Create barren deserts,
              lush jungles or a maze of wetlands.
            </p>
          </Col>
        </Row>
      </div>
      <div className="feature">
        <Row classNames="row--align-items-center row--justify-content-center">
          <Col span={4} md={6}>
            <h3>Fill it with adventure</h3>
            <p>
              In Untold Worlds you can create and control the races and
              locations that are generated. Create dwarves that crave the
              mountains and the generator will place them there. Create or find
              a template for their settlements and the generator will also be
              able to roll up the details of their dwarven fortress.
            </p>
          </Col>
          <Col span={6} md={6}>
            <video
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
              width={"100%"}
              style={{ maxWidth: "100%" }}
            >
              <source src={Step3WebM} type="video/webm" />
              <source src={Step3Mp4} type="video/mp4" />
            </video>
          </Col>
        </Row>
      </div>
      <div className="feature">
        <Row>
          <Col>
            <div style={{ textAlign: "center" }}>
              <h3>Get it to the table</h3>
              <div className="section__copy-centered">
                <p>
                  Untold Worlds is more than a map maker. Once finished you will
                  have an interactive setting that you can use to manage your
                  campaign. Create NPCs from templates, roll on random tables
                  and manage your session notes all in one place.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <video
            autoPlay={true}
            loop={true}
            muted={true}
            style={{ maxWidth: "100%" }}
            width={"100%"}
            className="shadow"
          >
            <source src={Step4WebM} type="video/webm" />
            <source src={Step4Mp4} type="video/mp4" />
          </video>
        </Col>
      </Row>
    </section>
  )
}

export default HowItWorks
