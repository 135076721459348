import "../components/Home/home.scss"

import React, { useEffect, useState } from "react"

import ArticleFeatures from "../components/Home/ArticleFeatures"
import CTADesert from "../images/cta--desert.jpg"
import CTAJungle from "../images/cta--jungle.jpg"
import Faq from "../components/Home/FAQ"
import Footer from "../components/Footer/Footer"
import FounderMessage from "../components/Home/FounderMessage"
import Hero from "../components/Home/Hero"
import HowItWorks from "../components/Home/HowItWorks"
import { Layout } from "../components/Layout/Layout"
import MailChimpFormContainer from "../components/Form/MailChimpFormContainer"
import Modal from "../components/Modal/Modal"
import Navbar from "../components/Navbar/Navbar"
import ProductIntro from "../components/Home/ProductIntro"
import RegisterCTA from "../components/Home/RegisterCTA"
import Seo from "../components/Seo/Seo"
import TemplateFeatures from "../components/Home/TemplateFeatures"

export default function Home() {
  const [modalVisible, setModalVisible] = useState(false)

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  useEffect(() => {
    modalVisible && (document.body.style.overflow = "hidden")
    !modalVisible && (document.body.style.overflow = "unset")
  }, [modalVisible])

  return (
    <>
      <Seo />
      <Layout>
        <Navbar handleRegister={toggleModal} />
        <Hero handleRegister={toggleModal} />
        <ProductIntro />
        <HowItWorks />
        <RegisterCTA toggleModal={toggleModal} imageUrl={CTAJungle} />
        <ArticleFeatures />
        <TemplateFeatures />
        <Faq />
        <RegisterCTA toggleModal={toggleModal} imageUrl={CTADesert} />
        <FounderMessage />
        <Footer />
        <Modal visible={modalVisible} toggleModal={toggleModal}>
          <MailChimpFormContainer toggle={toggleModal} />
        </Modal>
      </Layout>
    </>
  )
}
