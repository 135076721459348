import { Col, Row } from "../Layout/Layout"

import React from "react"
import SceneImage from "../../images/template-scene--1400.png"

const TemplateFeatures = () => {
  return (
    <section className="section section--alternate-bg templateFeatures__section">
      <Row classNames="row--align-items-center">
        <Col span={7}>
          <h2 className="primary-gradient">Spend less time on prep</h2>
          <p style={{ maxWidth: "600px" }}>
            Writing up the details for every NPC, dungeon room or village tavern
            can be a time consuming and tiring task. With templates you can skip
            all that busy work by having them all generated for you. Don't like
            the results? Roll again!
          </p>
        </Col>
        <Col span={5}>
          <img
            src={SceneImage}
            className="shadow articleMain__img"
            alt="An example template that helps users generate ruins for their fantasy campaigns"

          />
        </Col>
      </Row>
    </section>
  )
}

export default TemplateFeatures
