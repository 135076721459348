import { Col, Row } from "../Layout/Layout"

import AvatarImg from "../../images/avatar.png"
import React from "react"

const FounderMessage = () => {
  return (
    <section className="section">
      <Row style={{ maxWidth: "750px" }}>
        <Col>
          <FounderCard>
            <div>
              <h3>Running tabletop RPGs is awesome,</h3>
              <h3>Burning out on prep work isn't!</h3>
            </div>
            <div>
              <p>
                I love running tabletop roleplaying games, but let's be honest,
                it can be a huge amount of work.
              </p>
              <p>Time and time again, I come up against the same hurdles:</p>
              <ul>
                <li>
                  Creating a beautiful map for my campaign is hard and time
                  consuming.
                </li>
                <li>
                  Filling it with interesting locations, treasure, monsters,
                  NPCs and flavour can be challenging creative work.
                </li>
              </ul>
              <p>
                <strong>
                  That's why I created Untold Worlds - to give game masters a
                  tool that could help them kick start their campaign ideas in
                  minutes instead of hours or days.
                </strong>
              </p>
              <p>
                With Untold Worlds your maps can be generated in seconds and
                adjusted to taste with simple editing tools. Templates and
                rollable tables can be combined to create content for any table
                top system or setting. Finally, With the sharing and content
                discovery features, you'll be able to harness the creativity of
                others directly in your own creations.
              </p>
              <p>Happy world building,</p>
              <FounderAvatar />
            </div>
          </FounderCard>
        </Col>
      </Row>
    </section>
  )
}

export default FounderMessage

const FounderAvatar = () => {
  return (
    <div>
      <div
        style={{
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          backgroundColor: "#c5c5c5",
        }}
      >
        <img src={AvatarImg} />
      </div>
      <p className="founderAvatar__name">Tom Wheeler</p>
      <p className="founderAvatar__position">Founder of Untold Worlds</p>
    </div>
  )
}

const FounderCard = props => {
  return <div className="shadow--desktop founderCard">{props.children}</div>
}
