import { useCallback, useEffect, useState } from "react"

const useScrollThreshold = (scrollThreshold = 100, defaultScrolled = false) => {
  const [hitScrollThreshold, setScrollThreshold] = useState(defaultScrolled)

  const toggleScrolled = useCallback(() => {
    const offset = window.scrollY
    if (offset > scrollThreshold) {
      !hitScrollThreshold && setScrollThreshold(true)
    } else {
      hitScrollThreshold && setScrollThreshold(false)
    }
  }, [hitScrollThreshold])

  useEffect(() => {
    const handleScroll = () => {
      toggleScrolled()
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [toggleScrolled])

  return hitScrollThreshold
}

export default useScrollThreshold
