import "./navbar.scss"

import NavbarItem from "./NavbarItem"
import { NavbarLogo } from "./NavbarLogo"
import NavbarRegister from "./NavbarRegister"
import React from "react"
import useScrollThreshold from "./useScrollThreshold"

interface NavbarProps {
  handleRegister: () => void
}

const Navbar: React.FC<NavbarProps> = ({ children, handleRegister }) => {
  const isSticky = useScrollThreshold(300)

  return (
    <header className={`navbar${isSticky ? " navbar--scrolled" : ""}`}>
      <div className="navbar__container">
        <NavbarLogo />
        <div className="navbar-items">
          <NavbarItem to="howTo">Create Maps</NavbarItem>
          <NavbarItem to="features">Manage Campaigns</NavbarItem>
          <NavbarItem to="faq">FAQ</NavbarItem>
          <NavbarRegister handleClick={handleRegister}>Register</NavbarRegister>
        </div>
      </div>
    </header>
  )
}

export default Navbar
