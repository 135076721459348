import { Link } from "react-scroll"
import React from "react"

interface NavbarItemProps {
  to: string
}

const NavbarItem: React.FC<NavbarItemProps> = ({ children, to }) => {
  return (
    <>
      <div className={`navbar-item navbar-link`}>
        <Link to={to} smooth={true}>
          {children}
        </Link>
      </div>
    </>
  )
}

export default NavbarItem
