import "./hero.scss"

import { Col, Row } from "../../components/Layout/Layout"

import Cloud from "../../images/cloud1.png"
import Cloud2 from "../../images/cloud2.png"
import Cloud3 from "../../images/cloud3.png"
import { GoChevronDown } from "react-icons/go"
import HeroBG from "../../images/herobg.jpg"
import React from "react"

const Hero = props => {
  return (
    <div className="home-hero">
      <div className="home-hero__video-wrap">
        <img
          src={HeroBG}
          className="home-hero__video"
          alt="A procedurally generated hexmap for tabletop games"
        />
        <img
          src={Cloud}
          className="cloud1"
          alt="A cloud that drifts across the map"
        />
        <img
          src={Cloud2}
          className="cloud2"
          alt="A cloud that drifts across the map"
        />
        <img
          src={Cloud3}
          className="cloud3"
          alt="A cloud that drifts across the map"
        />
        <div className="home-hero__cover" />
      </div>

      <div className="home-hero__content">
        <Row>
          <Col span={12}>
            <div className="home-hero__title">
              The World Building Toolkit for busy game masters
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="home-hero__heading">
              Use Untold Worlds to generate customisable worlds and campaign
              settings so you can spend less time on prep.
            </div>
          </Col>
        </Row>
        <div>
          <button
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "select_content", {
                  content_type: "register--hero",
                })
              props.handleRegister()
            }}
            className="home-hero__register"
          >
            REGISTER YOUR INTEREST
          </button>
        </div>
        <div className="home-hero__guide">
          <GoChevronDown className="home-hero__arrow1" />
          <GoChevronDown className="home-hero__arrow2" />
        </div>
      </div>
    </div>
  )
}

export default Hero
