import "./socialButton.scss"

import { OutboundLink } from "gatsby-plugin-google-gtag"
import React from "react"

interface SocialButtonProps {
  to?: string
}

const SocialButton: React.FC<SocialButtonProps> = ({ children, to }) => {
  return (
    <OutboundLink
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className="socialButton__wrap"
    >
      {children}
    </OutboundLink>
  )
}

export default SocialButton
