import "./articleFeatures.scss"

import { Col, Row } from "../Layout/Layout"

import ArticleBG from "../../images/article-bg.png"
import Feature1 from "../../images/article-feature-1.png"
import Feature2 from "../../images/article-feature-2.png"
import Feature3 from "../../images/article-feature-3.png"
import React from "react"
import SceneImage from "../../images/article-scene--1400.png"

const ArticleFeatures = () => {
  return (
    <section id="features" className="section articleFeatures__section">
      <Row classNames="articleFeatures__row row--align-items-center">
        <Col span={7}>
          <h2 className="primary-gradient">Organise your world, your way</h2>
          <p style={{ maxWidth: "600px" }}>
            Expand upon the worlds you generate with articles for anything you
            can imagine. Flesh out your map's locations with descriptions, NPCs,
            monsters, treasure and maps. Organise and categorise your world's
            content however you like.
          </p>
        </Col>
        <Col span={5}>
          <img src={SceneImage} className="shadow articleMain__img" alt="An example article that describes a fantasy city" />
        </Col>
      </Row>

      <Row>
        <Col span={6} classNames="articleFeatures__bg-wrap">
          <img src={ArticleBG} className="articleFeatures__bg-img" alt="An article that details a military camp in a game master's campaign" />
        </Col>
      </Row>
      <div>
        <div className="feature">
          <Row classNames="row--align-items-center">
            <Col span={5}>
              <h3>Tie it all together</h3>
              <p>
                When you mention other articles we will automatically add a link
                to it, allowing you to quickly move around your world's notes.
              </p>
            </Col>
            <Col span={5}>
              <img
                src={Feature1}
                style={{ maxWidth: "100%" }}
                className="shadow"
                alt='Mentions provide a quick way of linking your articles together'
              />
            </Col>
          </Row>
        </div>
        <div className="feature">
          <Row classNames="row--align-items-center">
            <Col span={5}>
              <h3>Generate text on the fly</h3>
              <p>
                Use Random tables to fuel your creative juices or step in when
                improvisation fails you. Instantly generate NPC names, randomise
                a room's treasure or let fate decide which monster your players
                will face next.
              </p>
            </Col>
            <Col span={5}>
              <img
                src={Feature2}
                style={{ maxWidth: "100%" }}
                className="shadow"
                alt="An example of using text generators to build content for your campaign"
              />
            </Col>
          </Row>
        </div>
        <div className="feature">
          <Row classNames="row--align-items-center">
            <Col span={5}>
              <h3>A text editor with game masters in mind</h3>
              <p>
                Quickly drop in lists, images and random tables to help you keep
                track of everything you need whilst running a game for your
                players.
              </p>
            </Col>
            <Col span={5}>
              <img
                src={Feature3}
                style={{ maxWidth: "100%" }}
                className="shadow"
                alt="The text editor's formatting features being used to detail a location in a way that helps at the table"
              />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  )
}

export default ArticleFeatures
