import { Col, Row } from "../Layout/Layout"

import React from "react"

const Faq = () => {
  return (
    <section id="faq" className="section">
      <div style={{ textAlign: "center" }}>
        <h2 className="primary-gradient">Frequently asked questions</h2>
      </div>
      <Row>
        <Col span={6}>
          <div>
            <h4>How do I get access? Is it available now?</h4>
            <p>
              Untold Worlds has not been released yet. Details about the early
              access release will be available soon.
            </p>
            <p>
              By registering your interest, you will be notified as release
              approaches.
            </p>
          </div>
          <div>
            <h4>Is it a map maker?</h4>
            <p>
              Yes, you can use the app to generate or create maps, but it's not
              all the app has to offer. It's also a set of world building tools
              to help you create, manage and share your world's content.
            </p>
          </div>
          <div>
            <h4>What is procedural generation?</h4>
            <p>
              Procedural generation is a means of allowing computers to create
              content automatically, instead of having the user do it. What
              might take you and I hours, may only take the computer seconds. We
              use it to create plausible maps and content for table top
              roleplaying games.
            </p>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <h4>How much will it cost?</h4>
            <p>
              We haven't settled on a pricing system yet. As it is an online
              app, a paid version would likely come in the form of a monthly or
              annual subscription.
            </p>
          </div>
          <div>
            <h4>Does it only support hex maps?</h4>
            <p>
              The map creator only supports hex maps. However, we do have plans
              to support uploading maps of any type. Uploaded maps won't support
              procedurally placed locations, but you will be able to manually
              place them.
            </p>
          </div>
          <div>
            <h4>Are there other tilesets for the maps? Can I use my own?</h4>
            <p>There are currently two tilesets:</p>
            <ul>
              <li>Classic - Designed to give a traditional hex map style</li>
              <li>Iconic - Hand draw tiles created by Iconic Maps</li>
            </ul>
            <p>
              There are plans to add more tilesets in the future. Support for
              uploading your own tiles is not currently prioritised on the
              roadmap.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default Faq
