import React from "react"
import UWLogo from "../../images/uw_logo_white.svg"

export const NavbarLogo = () => {
  return (
    <div className="navbar-logo__wrap">
      <img className="navbar-logo__image" src={UWLogo} alt='Untold Worlds logo' />
    </div>
  )
}
