import React from "react"

interface RegisterButtonProps {
  handleClick: () => void
}

const NavbarRegister: React.FC<RegisterButtonProps> = ({
  children,
  handleClick,
}) => {
  return (
    <button
      className="navbar-register navbar-link navbar-item"
      onClick={() => {
        typeof window !== "undefined" &&
          (window as any).gtag("event", "select_content", {
            content_type: "register--navbar",
          })
        handleClick()
      }}
    >
      {children}
    </button>
  )
}

export default NavbarRegister
