import "./input.scss"

import React from "react"

interface FormInputProps {
  label: string
  name: string
  type: "text" | "submit" | "password" | "date" | "email" | "image"
  onChange: (value: any) => void
  value: any
  className?: string
  placeholder?: string
  error?: string
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  name,
  type,
  onChange,
  value,
  className,
  placeholder,
  error,
}) => {
  if (type === "submit") {
    return (
      <>
        <input
          type={"submit"}
          value={label}
          className={`submit ${className ? className : ""}`}
        />
      </>
    )
  }

  return (
    <>
      <div className="form__label">
        <label htmlFor={name} className="label">
          {label}
        </label>
      </div>
      <input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
        className={`input ${className ? className : ""}`}
      />
    </>
  )
}

export default FormInput
