import "./modal.scss"

import React, { MouseEvent } from "react"

interface ModalProps {
  visible: boolean
  toggleModal: () => void
}

const Modal: React.FC<ModalProps> = ({ visible, children, toggleModal }) => {
  const handleMaskClick = (e: MouseEvent) => {
    toggleModal()
    e.stopPropagation()
  }

  const handleContainerClick = (e: MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <div
      style={visible ? { display: "block" } : { display: "none" }}
      className="modal__background"
      onClick={handleMaskClick}
    >
      <div className="modal__wrap">
        <div className="modal__container" onClick={handleContainerClick}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
