import "./form.scss"

import Button, { KIND } from "../Button/Button"
import React, { MouseEvent, useEffect, useState } from "react"

import FormInput from "../Input/Input"

interface MailChimpFormProps {
  onValidated: any
  status: any
  message: any
  toggle: () => void
}

const MailChimpForm: React.FC<MailChimpFormProps> = ({
  onValidated,
  status,
  message,
  toggle,
}) => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")

  useEffect(() => {
    if (status === "success") clearFields()
  }, [status])

  const clearFields = () => {
    setEmail("")
    setName("")
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    onValidated({ EMAIL: email, MERGE1: name })
  }

  const handleCancel = (e: MouseEvent) => {
    e.preventDefault()
    clearFields()
    toggle()
  }

  const successContent = (
    <div>
      <h4>Success</h4>
      <p>Thank you for registering!</p>
      <Button onClick={handleCancel}>Close</Button>
    </div>
  )

  return status === "success" ? (
    successContent
  ) : (
    <form onSubmit={handleSubmit}>
      <h3>Register for updates</h3>
      {!status && (
        <div className="form__register-message ">
          Thanks for your interest in Untold Worlds. Register below to subscribe
          for product updates.
        </div>
      )}

      {status === "sending" && <div>sending...</div>}
      {status === "error" && (
        <div className="form__status form__status--error">
          <div className="form__status-heading form__status-heading--error">
            Error
          </div>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      )}
      {status === "success" && (
        <div dangerouslySetInnerHTML={{ __html: message }} />
      )}

      <div>
        <div className="form__input">
          <FormInput
            label="Email"
            name="email"
            type="email"
            onChange={setEmail}
            value={email}
            placeholder="Enter your email"
          />
        </div>
        <div className="form__input">
          <FormInput
            label="Name"
            name="name"
            type="text"
            onChange={setName}
            value={name}
            placeholder="Enter your name"
          />
        </div>
        <div className="form__footer">
          <Button kind={KIND.primary} onClick={handleSubmit}>
            Register
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
    </form>
  )
}

export default MailChimpForm
