import MailChimpForm from "./MailChimpForm"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import React from "react"

interface MailChimpFormContainerProps {
  toggle: () => void
}

const MailChimpFormContainer: React.FC<MailChimpFormContainerProps> = ({
  toggle,
}) => {
  const url = `${process.env.GATSBY_MAIL_URL}?u=${process.env.GATSBY_MAIL_U}&id=${process.env.GATSBY_MAIL_ID}`
  return (
    <div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <MailChimpForm
            status={status}
            message={message}
            onValidated={formData => {
              typeof window !== "undefined" &&
                window.gtag("event", "generate_lead")
              subscribe(formData)
            }}
            toggle={toggle}
          />
        )}
      />
    </div>
  )
}

export default MailChimpFormContainer
