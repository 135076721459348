import "./registerCTA.scss"

import Button, { KIND } from "../Button/Button"

import React from "react"

interface RegisterCTAProps {
  toggleModal: () => void
  imageUrl: any
}

const RegisterCTA: React.FC<RegisterCTAProps> = ({ toggleModal, imageUrl }) => {
  return (
    <section
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
      className="section registerCTA__wrap"
    >
      <div style={{ textAlign: "center" }}>
        <h2>Register your interest for early access</h2>
        <p>
          Register for the early bird's list and gain access to a limited offer
          when we launch.
        </p>
        <Button
          kind={KIND.primary}
          onClick={() => {
            typeof window !== "undefined" &&
              (window as any).gtag("event", "select_content", {
                content_type: "register--cta",
              })
            toggleModal()
          }}
        >
          Get Notified
        </Button>
      </div>
    </section>
  )
}

export default RegisterCTA
